import { createContext, PropsWithChildren, ReactElement, useCallback, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAuthentication } from '@onemedical/auth';

import allRoles from '../../data/roles';

export type Role = {
  normalizedId: string;
  name: string;
};

export const rolesContext = createContext<{ id?: string; roles: Role[]; rolesLoading: boolean }>({
  roles: [],
  rolesLoading: false,
});

export const useProfile = () => {
  const { id, roles } = useContext(rolesContext);

  const hasRole = useCallback(
    (role: keyof typeof allRoles) =>
      roles.some((r) => r.normalizedId === allRoles[role].toString()),
    [roles],
  );

  return { id, hasRole };
};

export const getInternalUserQuery = gql`
  query GetInternalUserRoles($id: ID) {
    internalUser(id: $id) {
      id
      roles {
        normalizedId
        name
      }
    }
  }
`;

export function ProfileProvider({
  children,
  id,
}: PropsWithChildren<{ id?: string }>): ReactElement {
  const [, { authenticated }] = useAuthentication();
  const { loading, data } = useQuery<{ internalUser: { id: string; roles: Role[] } }>(
    getInternalUserQuery,
    {
      variables: { id },
      skip: !authenticated,
    },
  );

  return (
    <rolesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        id: data?.internalUser.id,
        roles: data?.internalUser.roles || [],
        rolesLoading: loading,
      }}
    >
      {children}
    </rolesContext.Provider>
  );
}
